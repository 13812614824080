import { graphql } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components'

interface ProjectProps {
  data: GatsbyTypes.ProjectQuery
}

const Project: React.FC<ProjectProps> = ({ data }) => {
  return (
    <Layout>
      <h1>{data.projectsJson?.name}</h1>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query Project($slug: String!) {
    projectsJson(fields: { slug: { eq: $slug } }) {
      id
      name
      leader
    }
  }
`
